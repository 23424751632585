export const getLinkOnDocument = (base64String: Maybe<string>, typeDocument = 'application/pdf') => {
  if (!base64String) return undefined

  const byteCharacters = atob(base64String)
  const byteNumbers = new Array(byteCharacters.length)

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)

  const blob = new Blob([byteArray], { type: typeDocument })

  return URL.createObjectURL(blob)
}
