import type { FC } from 'react'
import React, { useCallback, useRef } from 'react'
import { HeadingVariant } from '@/components/composed/ConsultingForm/constants'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { ConsultingFormModal } from '@/components/composed/modals'
import asyncEpics from '@/logic/financing/epics'
import { ApplyingFinancingOfferSuccessModal } from '@/pages/Financing/components'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { IFinancingConsultingFormProps } from './components/ConsultingForm/types'
import { ConsultingConditions, ConsultingForm, ConsultingHeader } from './components'
import './styles.scss'

addModalToWellKnown({ ApplyingFinancingOfferSuccessModal })
addModalToWellKnown({ ConsultingFormModal })
addNewEpicsLazily([asyncEpics])

const FinancingConsulting: FC = () => {
  const firstInputRef = useRef() as NonNullable<IFinancingConsultingFormProps['firstInputRef']>

  const { isMobile } = useDeviceType()

  const onHeaderClick = useCallback(() => {
    if (!firstInputRef.current || !isMobile) return

    firstInputRef.current.focus()
    firstInputRef.current.scrollIntoView({ block: 'nearest' })
  }, [isMobile])

  return (
    <div className="financingConsulting elevatableBlock">
      <div className="financingConsulting__info">
        <ConsultingHeader onClick={onHeaderClick} />
        <ConsultingConditions />
      </div>
      <div className="financingConsulting__form">
        <ConsultingForm
          firstInputRef={firstInputRef}
          headingVariant={HeadingVariant.FORM_ON_MAIN_FINANCING_PAGE}
          footerClassName="financingConsulting__formFooter"
          footerLinkClassName="color_green"
        />
      </div>
    </div>
  )
}

export default FinancingConsulting
